<template>
  <MediaMentions :media-mentions="combinedMediaMentionsConfigWithElementData" />
</template>

<script setup lang="ts">
import MediaMentions from "@/components/organisms/MediaMentions";
import { type CmsElement } from "@/types/cms";
import { type MediaMention } from "@/components/organisms/MediaMentions/MediaMentions.interface";
import type { Schemas } from "#shopware";

const props = defineProps<{
  content: CmsElement<{
    mediaMentions: MediaMention[];
  }>;
}>();

const elementData = props.content.data as Schemas["Media"][];

const updatedConfigMedias = props.content.config?.mediaMentions?.value?.map(
  (mediaMention) => {
    const mediaObjOrId = mediaMention.media?.value;
    const mediaId = mediaObjOrId?.["id"] ?? mediaObjOrId;
    return elementData?.find((media) => media.id === mediaId);
  },
);

const combinedMediaMentionsConfigWithElementData =
  props.content.config.mediaMentions?.value?.map(
    (mediaMention, mediaMentionIdx) => {
      return {
        ...mediaMention,
        media: {
          ...mediaMention.media,
          value: updatedConfigMedias?.[mediaMentionIdx],
        },
      };
    },
  ) as MediaMention[];
</script>
